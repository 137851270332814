<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <div slot="action" slot-scope="{ data }">
        <el-button type="text" @click="toDetail(data.row)">查看详情</el-button>
        <el-button
          v-if="!data.row.status"
          type="text"
          @click="
            detailData = data.row;
            editShow = true;
          "
          >编辑</el-button
        >
        <el-button
          @click="firmDelete(data.row)"
          type="text"
          style="color: #f64a2d"
          >删除</el-button
        >
      </div>
      <div slot="insured" slot-scope="{ data }">
        <span v-if="data.row.insured" style="color: #00bc0d">已投保</span>
        <span v-else style="color: #f64a2d">未投保</span>
      </div>
      <div slot="status" slot-scope="{ data }">
        <span v-if="data.row.status == 1" style="color: #00bc0d">已服务</span>
        <span v-else-if="data.row.status == 2" style="color: #f64a2d"
          >已取消</span
        >
        <span v-else style="color: #f64a2d">未处理</span>
      </div>
    </SimpleTable>
    <TrafficAppointDetailsDialog
      :show.sync="show"
      :detailData="detailData"
    ></TrafficAppointDetailsDialog>
    <el-dialog title="处理" :visible.sync="editShow" width="30%">
      <div>
        <TextInput
          v-model="detailData.status"
          :config="{
            type: 'radio',
            label: '修改状态为',
            width: '100%',
            option: [
              {
                label: '已服务',
                value: 1,
              },
              {
                label: '已取消',
                value: 2,
              },
            ],
          }"
        ></TextInput>
        <TextInput
          v-if="detailData.status == 1"
          v-model="detailData.collectServiceExpense"
          :config="{
            type: 'input',
            label: '收取服务费用',
            isNum: true,
            width: '100%',
          }"
        ></TextInput>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="dealAppoint(null, false)"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SimpleTable from "@/components/SimpleTable";
import TextInput from "@/components/SimpleTable/TextInput.vue";
import TrafficAppointDetailsDialog from "../components/TrafficAppointDetailsDialog";
import {
  trafficArticleAppointmentList,
  trafficArticleAppointmentDelete,
  trafficArticleAppointmentChange,
} from "@/api/safeDuty.js";
import {
  sdTrafficIndustry,
  sdTrafficServiceType,
  sdTrafficServiceStatus,
} from "../js/const";
export default {
  components: { SimpleTable, TrafficAppointDetailsDialog, TextInput },
  data() {
    return {
      show: false,
      editShow: false,
      detailData: {},
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "index",
        currentSizeKey: "size",
        totalKey: "total",
        height: 500,
        searchConfigs: [
          {
            label: "企业名称",
            type: "input",
            modelKey: "companyName",
          },
          {
            label: "地址",
            type: "input",
            modelKey: "address",
          },
          {
            label: "所属行业",
            type: "select",
            modelKey: "industry",
            option: sdTrafficIndustry,
          },
          {
            label: "上传用户",
            type: "input",
            modelKey: "userName",
          },
          {
            label: "提交时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            // daterangeFormats: ["YYYY-MM-DD", "YYYY-MM-DD"],
            modelKey: "createT",
          },
          {
            label: "联系人",
            type: "input",
            modelKey: "contactPerson",
          },
          {
            label: "是否投保",
            type: "select",
            modelKey: "insured",
            option: [
              { dictKey: "", dictValue: "全部" },
              { dictKey: 1, dictValue: "是" },
              { dictKey: 0, dictValue: "否" },
            ],
          },
          {
            label: "服务类型",
            type: "select",
            modelKey: "serviceType",
            option: sdTrafficServiceType,
          },
          {
            label: "处理状态",
            type: "select",
            modelKey: "status",
            option: sdTrafficServiceStatus,
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "companyName",
            label: "企业名称",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "industryName",
            label: "所属行业",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "address",
            label: "地址",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "contactPerson",
            label: "联系人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "contactPhone",
            label: "联系电话",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insured",
            label: "是否投保",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },

          {
            prop: "serviceTypeName",
            label: "服务类型",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "serviceDescription",
            label: "服务说明",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "status",
            label: "状态",
            width: "",
            type: "",
            slot: true,
            isShow: true,
          },
          {
            prop: "collectServiceExpense",
            label: "服务费",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "createTime",
            label: "提交时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "180",
            type: "",
            slot: true,
            isShow: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await trafficArticleAppointmentList(queryParams);
      return {
        localData: res.data.records || [],
        total: res.data.total,
      };
    },
    // 跳转详情或编辑
    toDetail(item) {
      // this.$router.push({
      //   name: "safeDutyExpertManagementDetail",
      //   params: {
      //     id: item?.id,
      //     isEdit: edit,
      //   },
      // });
      //console.log(item);
      this.detailData = item;
      this.show = true;
    },
    dealAppoint(item, type = true) {
      let obj = {};
      if (item) {
        this.detailData = item;
        obj = item;
      } else {
        obj = this.detailData;
      }
      this.editShow = type;
      trafficArticleAppointmentChange({
        id: obj.id,
        status: obj.status,
        collectServiceExpense: obj.collectServiceExpense,
      }).then(() => {
        this.$refs.table.getData();
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
      });
      // this.$confirm("是否修改状态为已处理？", "编辑处理状态", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   trafficArticleAppointmentChange({ id: item.id }).then(() => {
      //     this.$refs.table.getData();
      //     this.$message({
      //       type: "success",
      //       message: "编辑成功!",
      //     });
      //   });
      // });
    },
    firmDelete(item) {
      this.$confirm("删除后对应数据也将清理，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        trafficArticleAppointmentDelete({ id: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
