var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SimpleTable',{ref:"table",attrs:{"tableProps":_vm.tableProps,"queryFun":_vm.queryFun},scopedSlots:_vm._u([{key:"action",fn:function({ data }){return _c('div',{},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toDetail(data.row)}}},[_vm._v("查看详情")]),(!data.row.status)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.detailData = data.row;
          _vm.editShow = true;}}},[_vm._v("编辑")]):_vm._e(),_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.firmDelete(data.row)}}},[_vm._v("删除")])],1)}},{key:"insured",fn:function({ data }){return _c('div',{},[(data.row.insured)?_c('span',{staticStyle:{"color":"#00bc0d"}},[_vm._v("已投保")]):_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v("未投保")])])}},{key:"status",fn:function({ data }){return _c('div',{},[(data.row.status == 1)?_c('span',{staticStyle:{"color":"#00bc0d"}},[_vm._v("已服务")]):(data.row.status == 2)?_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v("已取消")]):_c('span',{staticStyle:{"color":"#f64a2d"}},[_vm._v("未处理")])])}}])}),_c('TrafficAppointDetailsDialog',{attrs:{"show":_vm.show,"detailData":_vm.detailData},on:{"update:show":function($event){_vm.show=$event}}}),_c('el-dialog',{attrs:{"title":"处理","visible":_vm.editShow,"width":"30%"},on:{"update:visible":function($event){_vm.editShow=$event}}},[_c('div',[_c('TextInput',{attrs:{"config":{
          type: 'radio',
          label: '修改状态为',
          width: '100%',
          option: [
            {
              label: '已服务',
              value: 1,
            },
            {
              label: '已取消',
              value: 2,
            },
          ],
        }},model:{value:(_vm.detailData.status),callback:function ($$v) {_vm.$set(_vm.detailData, "status", $$v)},expression:"detailData.status"}}),(_vm.detailData.status == 1)?_c('TextInput',{attrs:{"config":{
          type: 'input',
          label: '收取服务费用',
          isNum: true,
          width: '100%',
        }},model:{value:(_vm.detailData.collectServiceExpense),callback:function ($$v) {_vm.$set(_vm.detailData, "collectServiceExpense", $$v)},expression:"detailData.collectServiceExpense"}}):_vm._e()],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.editShow = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.dealAppoint(null, false)}}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }